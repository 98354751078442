import browserUpdate from 'browser-update/update.npm.js';
browserUpdate({
  required: {
    e: -1,
    f: -2,
    o: 0,
    s: -2,
    c: 75
  },
  insecure: false,
  unsupported: false,
  style: 'top',
  reminder: 0,
  reminderClosed: 0,
  text: "<strong>Your browser may no longer be optimal for use with DBQ.</strong> You can continue but may encounter some issues. <br/>For the best experience, we recommend updating your browser. Please contact your school IT department. Your current version is {brow_name}.<div class='buorg-dismiss'>&times;</div>"
});

// dismiss the browser checker
document.addEventListener('click', function (event) {
	if (!event.target.matches('.buorg-dismiss')) return;
	event.preventDefault();
  var buorg = document.getElementById('buorg')
  var loginBg = document.getElementById('randomBGPic')
  buorg.style.display = 'none';
  loginBg.style.marginTop = '-52px';
}, false);